var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "dialogDrag",
              rawName: "v-dialogDrag",
              value: true,
              expression: "true",
            },
          ],
          attrs: {
            visible: _vm.OpenLink,
            title: "链接选择器",
            "before-close": _vm.close,
            width: "1300px",
          },
        },
        [
          _c("div", { staticClass: "x-x" }, [
            _c(
              "div",
              { staticClass: "collapse marR20" },
              [
                _c(
                  "el-collapse",
                  {
                    model: {
                      value: _vm.activeNames,
                      callback: function ($$v) {
                        _vm.activeNames = $$v
                      },
                      expression: "activeNames",
                    },
                  },
                  [
                    _c(
                      "el-collapse-item",
                      { attrs: { title: "商城", name: "1" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "cursorP padL30 collapseItem",
                            class: { collapseActive: _vm.activeIndex === 0 },
                            on: {
                              click: function ($event) {
                                return _vm.getCollapse(0)
                              },
                            },
                          },
                          [_vm._v(" 商城页面 ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "cursorP padL30 collapseItem",
                            class: { collapseActive: _vm.activeIndex === 3 },
                            on: {
                              click: function ($event) {
                                return _vm.getCollapse(3)
                              },
                            },
                          },
                          [_vm._v(" 自定义页面 ")]
                        ),
                      ]
                    ),
                    _c(
                      "el-collapse-item",
                      { attrs: { title: "商品", name: "2" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "cursorP padL30 collapseItem",
                            class: { collapseActive: _vm.activeIndex === 1 },
                            on: {
                              click: function ($event) {
                                return _vm.getCollapse(1)
                              },
                            },
                          },
                          [_vm._v(" 商品列表 ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "cursorP padL30 collapseItem",
                            class: { collapseActive: _vm.activeIndex === 2 },
                            on: {
                              click: function ($event) {
                                return _vm.getCollapse(2)
                              },
                            },
                          },
                          [_vm._v(" 商品分类列表 ")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: this.activeIndex === 0,
                    expression: "this.activeIndex === 0",
                  },
                ],
                staticClass: "linkContent flex1",
              },
              [
                _c("div", [
                  _c("div", { staticClass: "fontS14c3 fontWeight7 marB20" }, [
                    _vm._v("商城页面"),
                  ]),
                  _c(
                    "ul",
                    _vm._l(_vm.mallPageList, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          staticClass: "fontS14c3",
                          class: { checked: _vm.liShopIndex === index },
                          style: { color: item.color },
                          on: {
                            click: function ($event) {
                              return _vm.getSelectLink(
                                "liShopIndex",
                                "mallPageList",
                                index
                              )
                            },
                            mouseover: function ($event) {
                              return _vm.getLiMouseover("mallPageList", index)
                            },
                            mouseleave: function ($event) {
                              return _vm.getLiMouseleave("mallPageList", index)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
                _c("div", { staticClass: "marT20" }, [
                  _c("div", { staticClass: "fontS14c3 fontWeight7 marB20" }, [
                    _vm._v("会员中心"),
                  ]),
                  _c(
                    "ul",
                    _vm._l(_vm.personalCenterList, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          staticClass: "fontS14c3",
                          class: { checked: _vm.liMyIndex === index },
                          style: { color: item.color },
                          on: {
                            click: function ($event) {
                              return _vm.getSelectLink(
                                "liMyIndex",
                                "personalCenterList",
                                index
                              )
                            },
                            mouseover: function ($event) {
                              return _vm.getLiMouseover(
                                "personalCenterList",
                                index
                              )
                            },
                            mouseleave: function ($event) {
                              return _vm.getLiMouseleave(
                                "personalCenterList",
                                index
                              )
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeIndex === 1,
                    expression: "activeIndex === 1",
                  },
                ],
                staticClass: "flex1",
              },
              [
                _c(
                  "div",
                  { staticClass: "marB20" },
                  [
                    _c("el-input", {
                      staticClass: "inputWidth",
                      attrs: { size: "mini", placeholder: "商品编码/名称" },
                      model: {
                        value: _vm.goodsQueryParams.query,
                        callback: function ($$v) {
                          _vm.$set(_vm.goodsQueryParams, "query", $$v)
                        },
                        expression: "goodsQueryParams.query",
                      },
                    }),
                    _c(
                      "el-button",
                      {
                        staticClass: "marL10",
                        attrs: {
                          type: "primary",
                          icon: "el-icon-search",
                          size: "mini",
                        },
                        on: { click: _vm.getGoodsList },
                      },
                      [_vm._v("搜索")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingGoodsTable,
                        expression: "loadingGoodsTable",
                      },
                    ],
                    attrs: {
                      "tooltip-effect": "dark",
                      data: _vm.goodsData,
                      border: "",
                      "max-height": "400",
                      height: "400",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "序号",
                        align: "center",
                        width: "80",
                        type: "index",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "商品编码",
                        align: "center",
                        prop: "goodsNo",
                        "min-width": "180",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "商品名称",
                        align: "center",
                        prop: "goodsName",
                        "min-width": "180",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "商品分类",
                        align: "center",
                        prop: "categoryName",
                        "min-width": "160",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "单位",
                        align: "center",
                        prop: "unitName",
                        "min-width": "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "规格",
                        align: "center",
                        prop: "goodsSpec",
                        "min-width": "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "价格",
                        align: "center",
                        prop: "salePrice",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        fixed: "right",
                        label: "操作",
                        align: "center",
                        width: "150",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "x-fc" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "btnSelect fontS14B",
                                    class: {
                                      checked:
                                        _vm.selectLinkObj.id ===
                                        scope.row.goodsId,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClick(
                                          "goods",
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 选择 ")]
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  attrs: {
                    total: _vm.goodstotal,
                    page: _vm.goodsQueryParams.pageNum,
                    limit: _vm.goodsQueryParams.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.goodsQueryParams, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.goodsQueryParams, "pageSize", $event)
                    },
                    pagination: _vm.getGoodsList,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeIndex === 2,
                    expression: "activeIndex === 2",
                  },
                ],
                staticClass: "flex1",
              },
              [
                _c(
                  "el-table",
                  {
                    attrs: {
                      data: _vm.categoryData,
                      border: "",
                      "max-height": "400",
                      height: "400",
                      "row-key": "categoryId",
                      "tree-props": {
                        children: "children",
                        hasChildren: "hasChildren",
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "categoryNo",
                        label: "商品分类编码",
                        align: "center",
                        "min-width": "220",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "categoryName",
                        label: "商品分类名称",
                        "min-width": "220",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        fixed: "right",
                        label: "操作",
                        align: "center",
                        width: "150",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "x-fc" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "btnSelect fontS14B",
                                    class: {
                                      checked:
                                        _vm.selectLinkObj.id ===
                                        scope.row.categoryId,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClick(
                                          "class",
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 选择 ")]
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column"),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeIndex === 3,
                    expression: "activeIndex === 3",
                  },
                ],
                staticClass: "flex1",
              },
              [
                _c(
                  "div",
                  { staticClass: "marB20" },
                  [
                    _c("el-input", {
                      staticClass: "inputWidth",
                      attrs: { size: "mini", placeholder: "页面名称" },
                      model: {
                        value: _vm.goodsQueryParams.query,
                        callback: function ($$v) {
                          _vm.$set(_vm.goodsQueryParams, "query", $$v)
                        },
                        expression: "goodsQueryParams.query",
                      },
                    }),
                    _c(
                      "el-button",
                      {
                        staticClass: "marL10",
                        attrs: {
                          type: "primary",
                          icon: "el-icon-search",
                          size: "mini",
                        },
                        on: { click: _vm.getTemplate },
                      },
                      [_vm._v("搜索")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadinTemplateTable,
                        expression: "loadinTemplateTable",
                      },
                    ],
                    attrs: {
                      "tooltip-effect": "dark",
                      data: _vm.templateData,
                      border: "",
                      "max-height": "400",
                      height: "400",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "序号",
                        align: "center",
                        width: "80",
                        type: "index",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "页面名称",
                        align: "center",
                        prop: "templateName",
                        "min-width": "180",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "页面类型",
                        align: "center",
                        prop: "templateType",
                        "min-width": "180",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.TEMPLATE_TYPE_TEXT[
                                        scope.row.templateType
                                      ]
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "备注",
                        align: "center",
                        prop: "describe",
                        "min-width": "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "修改人",
                        align: "center",
                        prop: "updateBy",
                        "min-width": "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "修改时间",
                        align: "center",
                        prop: "updateTime",
                        "min-width": "155",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        fixed: "right",
                        label: "操作",
                        align: "center",
                        width: "150",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "x-fc" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "btnSelect fontS14B",
                                    class: {
                                      checked:
                                        _vm.selectLinkObj.id ===
                                        scope.row.templateId,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClick(
                                          "template",
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 选择 ")]
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  attrs: {
                    total: _vm.templatetotal,
                    page: _vm.templateQueryParams.pageNum,
                    limit: _vm.templateQueryParams.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(
                        _vm.templateQueryParams,
                        "pageNum",
                        $event
                      )
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(
                        _vm.templateQueryParams,
                        "pageSize",
                        $event
                      )
                    },
                    pagination: _vm.getTemplate,
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.getConfirm },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.close } },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }