<template>
  <div class="wrapNav x-bc">
    <div class="x-fc">
      <!-- 单据日期  -->
      <div v-if="isBillDate" class="x-f">
        <span class="fS14MR10">{{ dataLabel }}</span>
        <el-date-picker unlink-panels size="mini" class="marR10" v-model="datetime" :type="dateTimeType"
          start-placeholder="开始日期" end-placeholder="结束日期"
          :value-format="dateTimeFormat[dateTimeType]">
        </el-date-picker>
        <!-- <timeRange
          format="datetimerange"
          v-model="model[item.keyIndex].model"
          :defaultValue="filterTimeStr"
          :data="[
            'today',
            'yesterday',
            'thisWeek',
            'lastWeek',
            'thisMonth',
            'lastMonth'
          ]"
        /> -->
        <el-radio-group v-model="filterTime" class="radioGroup" size="mini">
          <el-radio-button label="today">今天</el-radio-button>
          <el-radio-button label="yesterday">昨天</el-radio-button>
          <el-radio-button label="thisWeek">本周</el-radio-button>
          <el-radio-button label="lastWeek">上周</el-radio-button>
          <el-radio-button label="thisMonth">本月</el-radio-button>
          <el-radio-button label="lastMonth">上月</el-radio-button>
        </el-radio-group>
      </div>
      <!-- 到货日期 -->
      <div v-if="isArrivalDateCom" class="x-f">
        <span class="fS14MR10">到货日期</span>
        <el-date-picker size="mini" class="marR10" v-model="datetimeArrival" type="datetimerange"
          start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"
          value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
        <!-- <el-radio-group
        v-model="filterTimeArrival"
        size="medium"
        class="radioGroup"
      >
        <el-radio-button label="today">今天</el-radio-button>
        <el-radio-button label="yesterday">昨天</el-radio-button>
        <el-radio-button label="thisWeek">本周</el-radio-button>
      </el-radio-group> -->
      </div>
      <!-- 搜索框 -->
      <!-- <el-tooltip
        v-if="isSearchInput"
        :disabled="disabledTooltip"
        effect="dark"
        :content="isSearchInputTitle"
        placement="top"
      > -->
      <!-- 自定义输入选择器 -->
      <selectAllFilter v-model="queryParams" v-if="isSearchInput" :filters="filters" />
      <!-- </el-tooltip> -->

      <!-- 查询条件 -->
      <div v-if="$slots.queryCondition">
        <slot name="queryCondition"></slot>
      </div>
    </div>
    <div class="x-fc">
      <el-button class="marR10" type="primary" size="mini" @click="getSearchList">查询</el-button>
      <div v-if="isAdvancedSearch || isNeedReset">
        <el-button :icon="icon" size="mini" @click="showHighSearch" v-if="!isNeedReset">高级查询
        </el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="refresh($event)">重置</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getTimes, getTimesIsPicker } from '@/utils/index.js'
import selectAllFilter from '@/components/tablePage/select/select-all-filter/index.vue' //搜索
import timeRange from '@/components/tablePage/time-range/index.vue'
export default {
  name: 'seniorSearch',
  components: {
    selectAllFilter,
    timeRange
  },
  data() {
    return {
      dateTimeFormat:{
        'daterange':'yyyy-MM-dd',
        'datetimerange':'yyyy-MM-dd HH:mm:ss',
      },
      isFilterTimeChange: true,
      isDatetime: false,
      filters: [{ filter: 'query', label: '全部' }],
      disabledTooltip: true, //输入框提示
      showHigh: false, //是否显示高级搜索
      icon: 'el-icon-caret-bottom', //高级搜索图标
      newToday: [], //今天
      newYesterday: [], //昨天
      newWeekStart: [], //本周
      datetime: undefined, //单据日期
      datetimeArrival: undefined, //到货查询时间
      filterTime: undefined, //天数
      filterTimeArrival: undefined //到货日期天数
    }
  },
  model: { prop: 'value', event: 'modelValue' },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    getList: {
      type: Function,
      default: function () { }
    },
    //单据日期快捷键
    filterTimeStr: {
      type: String,
      default: ''
    },
    //搜索内容
    dropDownList: {
      type: Array,
      default: function () {
        return [{ parameter: 'query', name: '全部' }]
      }
    },
    //高级搜索
    isAdvancedSearch: {
      type: Boolean,
      default: true
    },
    //单据日期
    isBillDate: {
      type: Boolean,
      default: true
    },
    //到货日期
    isArrivalDateCom: {
      type: Boolean,
      default: false
    },
    //搜索框
    isSearchInput: {
      type: Boolean,
      default: false
    },
    //搜索框文本提示
    isSearchInputTitle: {
      type: String || Number,
      default: '全部'
    },
    //重置搜索条件
    refreshSearch: {
      type: Object,
      default: function () {
        return {
          pageNum: 1,
          pageSize: 15
        }
      }
    },
    //日期文本
    dataLabel: {
      type: String,
      default: '单据日期'
    },
    //增加的日期文本--苏
    dataLabelOrder: {
      type: String,
      default: '预订日期'
    },
    // 重置按钮
    isNeedReset: {
      type: Boolean,
      default: false
    },
    // 打开页面是否调用接口按钮
    isInvokeList: {
      type: Boolean,
      default: true
    },
    dateTimeType:{
      type:String,
      default:'datetimerange'
    }
  },
  computed: {
    queryParams: {
      get() {
        return this.value
      },
      set(e) {
        this.$emit('modelValue', e)
      }
    }
  },
  watch: {
    //搜索条件
    dropDownList: {
      //监听的数组
      deep: true, //深度监听设置为 true
      immediate: true,
      handler: function (newV, oldV) {
        this.filters = this.convertParameters(newV)
      }
    },
    //搜索框文本提示
    isSearchInputTitle: {
      handler(newVal) {
        if (newVal) {
          const re = /[\u4E00-\u9FA5]/g //测试中文字符的正则
          if (re.test(newVal)) {
            //使用正则判断是否存在中文
            if (newVal.match(re).length > 11) {
              this.disabledTooltip = false
              return
            } else {
              this.disabledTooltip = true
              return
            }
          }
        }
      },
      immediate: true // 初次监听即执行
    },
    //单据日期
    datetime(newVal) {
      if (newVal) {
        this.filterTime = getTimesIsPicker(newVal,this.dateTimeType)
        this.queryParams.beginBillDate = newVal[0]
        this.queryParams.endBillDate = newVal[1]
      } else {
        this.queryParams.beginBillDate = undefined
        this.queryParams.endBillDate = undefined
        this.filterTime = undefined
      }
    },
    //到货日期
    datetimeArrival(newVal) {
      if (newVal) {
        this.filterTimeArrival = getTimesIsPicker(newVal)
        this.queryParams.beginArrivalDate = newVal[0]
        this.queryParams.endArrivalDate = newVal[1]
      } else {
        this.queryParams.beginArrivalDate = undefined
        this.queryParams.endArrivalDate = undefined
        this.filterTimeArrival = undefined
      }
    },
    //单据日期父传子
    filterTimeStr: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.filterTime = newVal
          //调用列表接口
          if (this.isInvokeList) {
            this.$nextTick(() => {
              this.getList()
            })
          }

          // 在这里执行相应的逻辑
        }
      }
    },
    //单据日期天数
    filterTime: {
      handler(newVal, oldVal) {
        if (newVal) {
          try {
            this.datetime = getTimes({
              type: newVal,
              format: this.dateTimeType
            }).filter(ite => typeof ite !== 'undefined')
            this.queryParams.beginBillDate = this.datetime[0]
            this.queryParams.endBillDate = this.datetime[1]
            //日期快捷触发
            // this.getList()
          } catch (error) { }
        }
      },
      immediate: true,
      deep: true
    },
    //到货日期天数
    filterTimeArrival(newVal) {
      if (newVal) {
        this.datetimeArrival = getTimes({
          type: newVal,
          format: 'datetimerange'
        }).filter(ite => typeof ite !== 'undefined')
        this.queryParams.beginArrivalDate = this.datetimeArrival[0]
        this.queryParams.endArrivalDate = this.datetimeArrival[1]
        //日期快捷触发
        // this.getList()
      }
    }
  },
  methods: {
    //搜索框字段转换
    convertParameters(arr) {
      return arr.map(obj => {
        return {
          filter: obj.parameter,
          label: obj.name
        }
      })
    },
    //是否显示高级搜索
    showHighSearch() {
      this.icon =
        this.icon === 'el-icon-caret-bottom'
          ? 'el-icon-caret-top'
          : 'el-icon-caret-bottom'
      this.showHigh = !this.showHigh
      this.$emit('isShowHighCom', this.showHigh)
    },
    //搜索方法
    getSearchList() {
      this.getList()
    },
    //重置按钮
    async refresh(e) {
      let target = e.target
      if (target.nodeName == 'I' || target.nodeName == 'SPAN') {
        target = e.target.parentNode
      }
      target.blur()
      this.datetime = undefined //单据日期
      this.datetimeArrival = undefined //到货查询时间
      this.filterTimeArrival = undefined //到货日期天数
      this.filterTime = undefined //天数
      //清空查询参数
      this.queryParams = JSON.parse(JSON.stringify(this.refreshSearch))
      console.log('filterTime', this.filterTime);
      await this.$nextTick(() => {
        console.log('filterTimeStr', this.filterTimeStr);
        this.filterTime = this.filterTimeStr //天数
      })
      await this.$nextTick(() => {
        this.getList()
      })
      this.$emit('resetCom')
    }
  }
}
</script>

<style lang="scss" scoped>
// 整体
.wrapNav {
  display: flex;
  align-items: center;
  //时间
  .radioGroup {
    margin-right: 20px;
  }
}
::v-deep .el-range-editor--mini .el-range-input {
  font-size: 14px;
}
//单据日期
// ::v-deep .el-date-editor--datetimerange.el-input,
// .el-date-editor--datetimerange.el-input__inner {
//   width: 355px;
// }
</style>
