//线性
export const lineIconList = [
  'icon-dingwei',
  'icon-a-quxiaodingdan11',
  'icon-huiyuan2',
  'icon-pinglun2',
  'icon-quanbudingdan',
  'icon-quxiaodingdan',
  'icon-youhuiquan1',
  'icon-daifukuan1',
  'icon-daifahuo12',
  'icon-jifenshangcheng2',
  'icon-jichu23-xianxing',
  'icon-ditu1-xianxing',
  'icon-ditu2-xianxing',
  'icon-erji1-xianxing',
  'icon-fahuochenggong-xianxing',
  'icon-gouwuche3-xianxing',
  'icon-liwu1-xianxing',
  'icon-jifen-xianxing',
  'icon-shouye1-xianxing',
  'icon-wujiaoxing-xianxing',
  'icon-wode7-xianxing',
  'icon-haoyou1-xianxing',
  'icon-jifenduihuan-xianxing',
  'icon-qianbao1-xianxing',
  'icon-ka2-xianxing',
  'icon-ka1-xianxing',
  'icon-mingxi8-xianxing',
  'icon-mingxi5-xianxing',
  'icon-pingfen-xianxing',
  'icon-ka5-xianxing',
  'icon-xiaoxi1-xianxing',
  'icon-tuikuan-xianxing',
  'icon-youhuiquan1-xianxing',
  'icon-daifahuo1',
  'icon-recharge',
  'icon-vipcard',
  'icon-group_light',
  'icon-rili',
  'icon-youhuiquan',
  'icon-mendianguanli1',
  'icon-store',
  'icon-fangdajing2-xianxing',
  'icon-pintuan2',
  'icon-response',
  'icon-cart-empty',
  'icon-gouwuche2-xianxing',
  'icon-jichu16-xianxing',
  'icon-shouye-qiehuan'
]
//面性
export const facialIconList = [
  'icon-gouwuche1-mianxing',
  'icon-huiyuan',
  'icon-wode',
  'icon-home',
  'icon-yuanxinggouwuche',
  'icon-yingyongzhongxin',
  'icon-morentubiao',
  'icon-huiyuan1',
  'icon-pinglun1',
  'icon-quxiaodingdan1',
  'icon-quanbudingdan1',
  'icon-a-dian2',
  'icon-jifenshangcheng1',
  'icon-jichu08',
  'icon-jichu05',
  'icon-jichu23-mianxing',
  'icon-ditu1-mianxing',
  'icon-erji2-mianxing',
  'icon-erji1-mianxing',
  'icon-fahuochenggong-mianxing',
  'icon-gouwuche3-mianxing',
  'icon-liwu1-mianxing',
  'icon-shouye1-mianxing',
  'icon-wode7-mianxing',
  'icon-wujiaoxing-mianxing',
  'icon-jichu03',
  'icon-jichu04',
  'icon-jichu06',
  'icon-jifen-mianxing',
  'icon-jifenduihuan-mianxing',
  'icon-haoyou1-mianxing',
  'icon-ka1-mianxing',
  'icon-qianbao1-mianxing',
  'icon-ka5-mianxing',
  'icon-ka2-mianxing',
  'icon-mingxi8-mianxing',
  'icon-pingfen-mianxing',
  'icon-mingxi5-mianxing',
  'icon-xiaoxi1-mianxing',
  'icon-youhuiquan1-mianxing',
  'icon-tuikuan-mianxing',
  'icon-rechargefill',
  'icon-group_fill_light',
  'icon-daifahuo2',
  'icon-daishouhuo2',
  'icon-lingquanzhongxin2',
  'icon-tuikuan2',
  'icon-daishouhuo1',
  'icon-shouhuodizhi2',
  'icon-mendianguanli2',
  'icon-store-fill',
  'icon-response-fill',
  'icon-cart-empty-fill',
  'icon-gouwuche2-mianxing',
  'icon-pintuan1',
  'icon-jichu16-mianxing'
]
//其他
export const otherIconList = [
  'iconfont icon-xiangzuojiaohuan',
  'iconfont icon-xianghujiaohuan',
  'iconfont icon-xiangyoujiaohuan',
  'iconfont icon-shuzhixiajiang',
  'iconfont icon-quanping',
  'iconfont icon-hengxiangzhankai',
  'iconfont icon-shuzhishangsheng',
  'iconfont icon-tuichuquanping',
  'iconfont icon-hengxiangshouqi',
  'iconfont icon-shuaxin',
  'iconfont icon-tongbu',
  'iconfont icon-jiazailoading-A',
  'iconfont icon-jiazailoading-B',
  'iconfont icon-weizhi-xianxing',
  'iconfont icon-weizhi',
  'iconfont icon-daohang',
  'iconfont icon-dingwei2',
  'iconfont icon-jiankongshexiangtou-xianxing',
  'iconfont icon-tuodiantu',
  'iconfont icon-jiankongshexiangtou',
  'iconfont icon-leidatance',
  'iconfont icon-baobiao',
  'iconfont icon-bingtu-xianxing',
  'iconfont icon-baobiao-xianxing',
  'iconfont icon-bingtu',
  'iconfont icon-tiaoxingtu',
  'iconfont icon-tiaoxingtu-xianxing',
  'iconfont icon-zhexiantu',
  'iconfont icon-zhexiantu-xianxing',
  'iconfont icon-zhinanzhidao-xianxing',
  'iconfont icon-ditu',
  'iconfont icon-yishouhuo',
  'iconfont icon-zhinanzhidao',
  'iconfont icon-tubiao14',
  'iconfont icon-tousu',
  'iconfont icon-shoucang2',
  'iconfont icon-xunjianjianyan',
  'iconfont icon-yishouhuo1',
  'iconfont icon-dianpu',
  'iconfont icon-qiandao',
  'iconfont icon-dianpu-xianxing',
  'iconfont icon-pintuanzhuanqu',
  'iconfont icon-kuaidiyuan',
  'iconfont icon-yishouhuo2',
  'iconfont icon-kuaidiyuan-xianxing',
  'iconfont icon-yunshuzhongwuliu-xianxing',
  'iconfont icon-daikuan-xianxing',
  'iconfont icon-yunshuzhongwuliu',
  'iconfont icon-huankuan-xianxing',
  'iconfont icon-baoguofahuo',
  'iconfont icon-tuikuan-xi',
  'iconfont icon-baoguofahuo-xianxing',
  'iconfont icon-rili-xianxing-xi',
  'iconfont icon-chaibaoguoqujian',
  'iconfont icon-jisuanqilishuai-xianxing-xi',
  'iconfont icon-chaibaoguoqujian-xianxing',
  'iconfont icon-gengduopintuan',
  'iconfont icon-zitigui',
  'iconfont icon-yonghuziliao',
  'iconfont icon-zitigui-xianxing',
  'iconfont icon-yonghuziliao-xianxing',
  'iconfont icon-caigou-xianxing',
  'iconfont icon-pifuzhuti',
  'iconfont icon-caigou',
  'iconfont icon-pifuzhuti-xianxing',
  'iconfont icon-shangpin',
  'iconfont icon-diamond',
  'iconfont icon-shangpin-xianxing',
  'iconfont icon-diamond-o',
  'iconfont icon-peizaizhuangche',
  'iconfont icon-kaifa-xianxing',
  'iconfont icon-peizaizhuangche-xianxing',
  'iconfont icon-yinhangqia',
  'iconfont icon-anquanbaozhang',
  'iconfont icon-yinhangqia-xianxing',
  'iconfont icon-anquanbaozhang-xianxing',
  'iconfont icon-hongbao-xianxing',
  'iconfont icon-cangkucangchu',
  'iconfont icon-hongbao',
  'iconfont icon-cangkucangchu-xianxing',
  'iconfont icon-qiandao1',
  'iconfont icon-zhongzhuanzhan-xianxing',
  'iconfont icon-groupbuy',
  'iconfont icon-zhongzhuanzhan',
  'iconfont icon-yishouhuo3',
  'iconfont icon-kucun-xianxing',
  'iconfont icon-yishouhuo4',
  'iconfont icon-kucun',
  'iconfont icon-yishouhuo5',
  'iconfont icon-moduanwangdian-xianxing',
  'iconfont icon-yishouhuo6',
  'iconfont icon-moduanwangdian',
  'iconfont icon-yishouhuo7',
  'iconfont icon-qianshoushenpitongguo-xianxing',
  'iconfont icon-qiandao2',
  'iconfont icon-qianshoushenpitongguo',
  'iconfont icon-pintuan',
  'iconfont icon-juqianshou-xianxing',
  'iconfont icon-qiandao3',
  'iconfont icon-juqianshou',
  'iconfont icon-yishouhuo8',
  'iconfont icon-jijianfasong-xianxing',
  'iconfont icon-qiandao4',
  'iconfont icon-jijianfasong',
  'iconfont icon-qiandao5',
  'iconfont icon-qiyeyuanquwuye',
  'iconfont icon-yishouhuo9',
  'iconfont icon-qiyeyuanquwuye-xianxing',
  'iconfont icon-yishouhuo10',
  'iconfont icon-jiesuan-xianxing',
  'iconfont icon-pintuan3',
  'iconfont icon-jiesuan1',
  'iconfont icon-pintuan4',
  'iconfont icon-jifen',
  'iconfont icon-shoucang4',
  'iconfont icon-jifen-xianxing1',
  'iconfont icon-qiandao6',
  'iconfont icon-youhuijuan-xianxing',
  'iconfont icon-shoucang5',
  'iconfont icon-ziliaoshouce',
  'iconfont icon-shoucang6',
  'iconfont icon-ziliaoshouce-xianxing',
  'iconfont icon-yishouhuo11',
  'iconfont icon-youhuijuan',
  'iconfont icon-shoucang7',
  'iconfont icon-guize',
  'iconfont icon-pintuan5',
  'iconfont icon-danju-xianxing',
  'iconfont icon-pintuan6',
  'iconfont icon-zuzhijiagoujiekou',
  'iconfont icon-pintuan7',
  'iconfont icon-danju',
  'iconfont icon-chuangjiandanju-xianxing',
  'iconfont icon-chuangjiandanju',
  'iconfont icon-zhangdan-xianxing',
  'iconfont icon-zhangdan',
  'iconfont icon-tijikongjian',
  'iconfont icon-tijikongjian-xianxing',
  'iconfont icon-yewu-xianxing',
  'iconfont icon-yewu',
  'iconfont icon-yingyongchengxu-xianxing',
  'iconfont icon-yingyongchengxu',
  'iconfont icon-biaozhun',
  'iconfont icon-biaozhun-xianxing',
  'iconfont icon-quanxianyuechi',
  'iconfont icon-quanxianyuechi-xianxing',
  'iconfont icon-ziyuan',
  'iconfont icon-ziyuan-xianxing',
  'iconfont icon-mobankuangjia-xianxing',
  'iconfont icon-mobankuangjia',
  'iconfont icon-xinwenzixun',
  'iconfont icon-xinwenzixun-xianxing',
  'iconfont icon-hezuoguanxi-xianxing',
  'iconfont icon-hezuoguanxi',
  'iconfont icon-xianlu',
  'iconfont icon--fuwu-xianxing',
  'iconfont icon--fuwu',
  'iconfont icon--kefu-xianxing',
  'iconfont icon--kefu',
  'iconfont icon--guoji-xianxing',
  'iconfont icon--guoji',
  'iconfont icon-haiguan-xianxing',
  'iconfont icon-haiguan',
  'iconfont icon-touchengkongyun',
  'iconfont icon-weicheng',
  'iconfont icon-caiwu',
  'iconfont icon-caiwu-xianxing',
  'iconfont icon-mianfei',
  'iconfont icon-mianfei-xianxing',
  'iconfont icon-tuikuan',
  'iconfont icon-jisuanqilishuai',
  'iconfont icon-jisuanqilishuai-xianxing',
  'iconfont icon-checkbox-weixuan',
  'iconfont icon-checkbox-xuanzhong',
  'iconfont icon-Raidobox-weixuan',
  'iconfont icon-Raidobox-xuanzhong',
  'iconfont icon-checkbox-xuanzhongbufen',
  'iconfont icon-youxiajiaogouxuan',
  'iconfont icon-sousuo-xianxing',
  'iconfont icon-shezhi-xianxing',
  'iconfont icon-shouye',
  'iconfont icon-shouye-xianxing',
  'iconfont icon-sousuo',
  'iconfont icon-wenti-xianxing',
  'iconfont icon-wenti',
  'iconfont icon-dianhua-xianxingyuankuang',
  'iconfont icon-liaotianduihua',
  'iconfont icon-liaotianduihua-xianxing',
  'iconfont icon-dianhua',
  'iconfont icon-dianhua-yuankuang',
  'iconfont icon-xin-xianxing',
  'iconfont icon-lingdang',
  'iconfont icon-lingdang-xianxing',
  'iconfont icon-xin',
  'iconfont icon-laba-xianxing',
  'iconfont icon-laba',
  'iconfont icon-maikefeng-xianxing',
  'iconfont icon-shoucang3',
  'iconfont icon-maikefeng',
  'iconfont icon-xihuan-xianxing',
  'iconfont icon-shoucang-xianxing',
  'iconfont icon-xihuan',
  'iconfont icon-gengduo-hengxiang',
  'iconfont icon-gengduo-shuxiang',
  'iconfont icon-shijian-xianxing',
  'iconfont icon-shengboyuyinxiaoxi',
  'iconfont icon-shijian',
  'iconfont icon-shangchuandaochu',
  'iconfont icon-xiazaidaoru',
  'iconfont icon-baocun-xianxing',
  'iconfont icon-shanguangdeng',
  'iconfont icon-shanguangdeng-zidong',
  'iconfont icon-shanguangdeng-guanbi',
  'iconfont icon-baocun',
  'iconfont icon-yonghu-xianxing',
  'iconfont icon-yonghu',
  'iconfont icon-jiaosequnti',
  'iconfont icon-morentouxiang',
  'iconfont icon-zhucetianjiahaoyou',
  'iconfont icon-renwu',
  'iconfont icon-zhongwenmoshi',
  'iconfont icon-fujian',
  'iconfont icon-bianjishuru-xianxing',
  'iconfont icon-bianjishuru',
  'iconfont icon-yingwenmoshi',
  'iconfont icon-jianpan-xianxing',
  'iconfont icon-jianpan',
  'iconfont icon-rili1',
  'iconfont icon-weichuqin',
  'iconfont icon-kaoqinchuqin',
  'iconfont icon-paizhao',
  'iconfont icon-paizhao-xianxing',
  'iconfont icon-tupian-xianxing',
  'iconfont icon-tupian',
  'iconfont icon-saomiao',
  'iconfont icon-xianshikejian',
  'iconfont icon-suoding',
  'iconfont icon-yincangbukejian',
  'iconfont icon-jiesuo',
  'iconfont icon-anzhuangshigong-xianxing',
  'iconfont icon-shaixuanguolv',
  'iconfont icon-anzhuangshigong',
  'iconfont icon-zhuxiaoguanji',
  'iconfont icon-haoping-yuankuang',
  'iconfont icon-chaping-yuankuang',
  'iconfont icon-chaping',
  'iconfont icon-haoping',
  'iconfont icon-yiban',
  'iconfont icon-manyi',
  'iconfont icon-bumanyi',
  'iconfont icon-liebiaoshitucaidan',
  'iconfont icon-gonggeshitu',
  'iconfont icon-Phoneshouji',
  'iconfont icon-PCtaishiji',
  'iconfont icon-PDAshouchigongzuoshebei',
  'iconfont icon-jia-fangkuang',
  'iconfont icon-jia-xianxingfangkuang',
  'iconfont icon-jia-xianxingyuankuang',
  'iconfont icon-jia-yuankuang',
  'iconfont icon-jian-fangkuang',
  'iconfont icon-jia',
  'iconfont icon-jian-yuankuang',
  'iconfont icon-jian',
  'iconfont icon-jian-xianxingfangkuang',
  'iconfont icon-zhengquewancheng-xianxingyuankuang',
  'iconfont icon-zhengquewancheng-yuankuang',
  'iconfont icon-zhengquewancheng',
  'iconfont icon-jian-xianxingyuankuang',
  'iconfont icon-cuowuguanbiquxiao-xianxingfangkuang',
  'iconfont icon-cuowuguanbiquxiao-yuankuang',
  'iconfont icon-cuowuguanbiquxiao-xianxingyuankuang',
  'iconfont icon-cuowuguanbiquxiao',
  'iconfont icon-cuowuguanbiquxiao-fangkuang',
  'iconfont icon-xinxi-xianxingyuankuang',
  'iconfont icon-xinxi',
  'iconfont icon-wenhao-xianxingyuankuang',
  'iconfont icon-wenhao1',
  'iconfont icon-wenhao-yuankuang',
  'iconfont icon-xinxi-yuankuang',
  'iconfont icon-gantanhao-sanjiaokuang',
  'iconfont icon-gantanhao-yuankuang',
  'iconfont icon-gantanhao-xianxingyuankuang',
  'iconfont icon-gantanhao-xianxingsanjiaokuang',
  'iconfont icon-gantanhao',
  'iconfont icon-shangyiyehoutuifanhui-xianxingyuankuang',
  'iconfont icon-shangyiyehoutuifanhui-yuankuang',
  'iconfont icon-xiayiyeqianjinchakangengduo-xianxingyuankuang',
  'iconfont icon-xiayiyeqianjinchakangengduo-yuankuang',
  'iconfont icon-shangyiyehoutuifanhui',
  'iconfont icon-xiayiyeqianjinchakangengduo',
  'iconfont icon-xiangxiazhankai-xianxingyuankuang',
  'iconfont icon-xiangxiazhankai-yuankuang',
  'iconfont icon-xiangxiazhankai',
  'iconfont icon-xiangshangshouqi-yuankuang',
  'iconfont icon-xiangshangshouqi-xianxingyuankuang',
  'iconfont icon-zhixiangzuo',
  'iconfont icon-zhixiangyou',
  'iconfont icon-zhixiangshang',
  'iconfont icon-xiangshangshouqi',
  'iconfont icon-zhixiangxia',
  'iconfont icon-diyiye',
  'iconfont icon-zuihouye',
  'iconfont icon-biaotou-zhengxu',
  'iconfont icon-biaotou-kepaixu',
  'iconfont icon-biaotou-daoxu',
  'iconfont icon-huidingbu',
  'iconfont icon-igw-l-switch',
  'iconfont icon-qiehuan',
  'iconfont icon-qiehuan_mian24',
  'iconfont icon-pengyouquan',
  'iconfont icon-shouye-qiehuan',
  'iconfont icon-a-xiaochengxutubiao2',
  'iconfont icon-shengchenghaibao',
  'iconfont icon-jichu13-xianxing',
  'iconfont icon-jichu13-mianxing',
  'iconfont icon-haibao',
  'iconfont icon-weixin',
  'iconfont icon-jichu17-mianxing',
  'iconfont icon-jichu17-xianxing',
  'iconfont icon-anniu2',
  'iconfont icon-anniu3',
  'iconfont icon-anniu4',
  'iconfont icon-anniu1',
  'iconfont icon-aixin-xianxing',
  'iconfont icon-aixin-mianxing',
  'iconfont icon-dingdan1-xianxing',
  'iconfont icon-dingdan1-mianxing',
  'iconfont icon-fahuofukuan-mianxing',
  'iconfont icon-fahuofukuan-xianxing',
  'iconfont icon-fanhui1',
  'iconfont icon-jichu10',
  'iconfont icon-jichu20-mianxing',
  'iconfont icon-jichu20-xianxing',
  'iconfont icon-wujiaoxing-mianxing',
  'iconfont icon-jichu16-xianxing',
  'iconfont icon-jichu16-mianxing',
  'iconfont icon-pintuan1',
  'iconfont icon-gouwuche2-mianxing',
  'iconfont icon-gouwuche2-xianxing',
  'iconfont icon-cart-empty',
  'iconfont icon-cart-empty-fill',
  'iconfont icon-response',
  'iconfont icon-response-fill',
  'iconfont icon-pintuan2',
  'iconfont icon-morentubiao',
  'iconfont icon-fangdajing2-xianxing',
  'iconfont icon-store-fill',
  'iconfont icon-store',
  'iconfont icon-mendianguanli2',
  'iconfont icon-mendianguanli1',
  'iconfont icon-haoyou1-xianxing',
  'iconfont icon-jifen-mianxing',
  'iconfont icon-jifenduihuan-mianxing',
  'iconfont icon-haoyou1-mianxing',
  'iconfont icon-jifenduihuan-xianxing',
  'iconfont icon-ka1-mianxing',
  'iconfont icon-qianbao1-xianxing',
  'iconfont icon-qianbao1-mianxing',
  'iconfont icon-ka5-mianxing',
  'iconfont icon-ka2-mianxing',
  'iconfont icon-ka2-xianxing',
  'iconfont icon-mingxi8-mianxing',
  'iconfont icon-ka1-xianxing',
  'iconfont icon-mingxi8-xianxing',
  'iconfont icon-pingfen-mianxing',
  'iconfont icon-mingxi5-mianxing',
  'iconfont icon-mingxi5-xianxing',
  'iconfont icon-pingfen-xianxing',
  'iconfont icon-ka5-xianxing',
  'iconfont icon-xiaoxi1-xianxing',
  'iconfont icon-xiaoxi1-mianxing',
  'iconfont icon-youhuiquan1-mianxing',
  'iconfont icon-tuikuan-mianxing',
  'iconfont icon-tuikuan-xianxing',
  'iconfont icon-youhuiquan1-xianxing',
  'iconfont icon-ditu1-xianxing',
  'iconfont icon-ditu1-mianxing',
  'iconfont icon-ditu2-xianxing',
  'iconfont icon-erji2-mianxing',
  'iconfont icon-erji1-xianxing',
  'iconfont icon-erji1-mianxing',
  'iconfont icon-fahuochenggong-mianxing',
  'iconfont icon-fahuochenggong-xianxing',
  'iconfont icon-gouwuche3-xianxing',
  'iconfont icon-gouwuche3-mianxing',
  'iconfont icon-liwu1-mianxing',
  'iconfont icon-liwu1-xianxing',
  'iconfont icon-jifen-xianxing',
  'iconfont icon-shouye1-xianxing',
  'iconfont icon-shouye1-mianxing',
  'iconfont icon-wode7-mianxing',
  'iconfont icon-wujiaoxing-xianxing',
  'iconfont icon-wode7-xianxing',
  'iconfont icon-jichu03',
  'iconfont icon-jichu04',
  'iconfont icon-jichu06',
  'iconfont icon-jichu08',
  'iconfont icon-jichu05',
  'iconfont icon-jichu23-mianxing',
  'iconfont icon-jichu23-xianxing',
  'iconfont icon-dingwei1',
  'iconfont icon-a-quxiaodingdan11',
  'iconfont icon-huiyuan1',
  'iconfont icon-pinglun1',
  'iconfont icon-huiyuan2',
  'iconfont icon-pinglun2',
  'iconfont icon-quxiaodingdan1',
  'iconfont icon-quanbudingdan1',
  'iconfont icon-a-dian2',
  'iconfont icon-quanbudingdan',
  'iconfont icon-quxiaodingdan',
  'iconfont icon-youhuiquan1',
  'iconfont icon-daifukuan1',
  'iconfont icon-daifahuo12',
  'iconfont icon-jifenshangcheng2',
  'iconfont icon-jifenshangcheng1',
  'iconfont icon-daifahuo2',
  'iconfont icon-daishouhuo2',
  'iconfont icon-lingquanzhongxin2',
  'iconfont icon-tuikuan2',
  'iconfont icon-daishouhuo1',
  'iconfont icon-shouhuodizhi2',
  'iconfont icon-rechargefill',
  'iconfont icon-recharge',
  'iconfont icon-vipcard',
  'iconfont icon-group_light',
  'iconfont icon-group_fill_light',
  'iconfont icon-rili',
  'iconfont icon-youhuiquan',
  'iconfont icon-daifahuo1',
  'iconfont icon-xiangxia',
  'iconfont icon-dingwei',
  'iconfont icon-xiaoxikefu',
  'iconfont icon-shoucang',
  'iconfont icon-shoucang1',
  'iconfont icon-mobandaoru',
  'iconfont icon-jiesuan',
  'iconfont icon-moren',
  'iconfont icon-qingchu',
  'iconfont icon-tubiao_qingchu',
  'iconfont icon-qizi',
  'iconfont icon-wangongguanlibeifen2x',
  'iconfont icon-assignPermissions-o',
  'iconfont icon-a-weibiaoti-1_huaban1fuben28',
  'iconfont icon-shengchanlingliao',
  'iconfont icon-ruku1',
  'iconfont icon-gouwuche',
  'iconfont icon-8tupian-2',
  'iconfont icon-huishouzhan_jiaodian',
  'iconfont icon-5',
  'iconfont icon-ruku',
  'iconfont icon-pandianyewu',
  'iconfont icon-wuliaoguanli',
  'iconfont icon-shouye2',
  'iconfont icon-kufangguanli-lingyongguihuan',
  'iconfont icon-Rrl_s_141',
  'iconfont icon-zhangdanguanli',
  'iconfont icon-qiandai',
  'iconfont icon-wenhao',
  'iconfont icon-huiyuanyingxiao',
  'iconfont icon-qian',
  'iconfont icon-shezhi',
  'iconfont icon-shangcheng',
  'iconfont icon-tianchongxing-',
  'iconfont icon-cangku',
  'iconfont icon-gerencanshu',
  'iconfont icon-wodezuhe',
  'iconfont icon-shengchanqiye',
  'iconfont icon-mendian',
  'iconfont icon-xiaoshou',
  'iconfont icon-shouye-yingxiaoguanli',
  'iconfont icon-wodezuhe-copy'
]
